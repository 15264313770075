*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.nav-col {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    color: #769fde;
    background: #000000;
}
.nav-col svg{
    height: 1.6em;
    width: 1.6em;
}
.nav-col svg#LSize{
    font-size: 4em;
}
.nav-col p{
    font-size: 12px;
}

.navBtnAnimate{
    color: #43b5ec;
    animation-name: animateZoom2;
    animation-duration: 0.2s;
    animation-timing-function: linear;
}

@keyframes animateZoom2 {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}