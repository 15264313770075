:root {
  --min-response-width: 430px;
}

.dialogView {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: var(--min-response-width);
  display: none;
  z-index: 1200;
  background: rgba(0, 0, 0, 0.5);
}
.dialogViewActive {
  display: block !important;
}

.dialogView .dialogViewContents{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  min-height: 88px;
  border-radius: 6px;
  transition: 0.2s;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
}


.dialogView .dialogViewContents p{
  color: #FFFFFF;
  font-size: 18px;
  margin-top: 15px;
}

.dialogView .dialogViewContents .dialogViewContentsLoading {
  width: 45px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid transparent;
  border-right-color: #FFFFFF;
  animation: spin 0.6s infinite linear;
  -webkit-animation: spin 0.6s infinite linear;
}

@keyframes spin{ 
  100%{
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
  }
}

@media (max-width: 450px) {
  /* responsive width */
  .dialogView {
    width: 100% !important;
  }
}
