.w10px {
  width: 10px !important;
}
.w30px {
  width: 30px !important;
}
.w40px {
  width: 40px !important;
}
.w50px {
  width: 50px !important;
}

.viewCenter {
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.flexDCol {
  flex-direction: column;
}

.fontS12Px {
  font-size: 12px;
}
.fontS13Px {
  font-size: 13px;
}
.fontS14Px {
  font-size: 14px;
}
.fontS16Px {
  font-size: 16px;
}
.fontS18Px {
  font-size: 18px;
}
.fontS20Px {
  font-size: 20px;
}
.fontS25Px {
  font-size: 25px;
}
.fontS30Px {
  font-size: 30px;
}

.fontW400 {
  font-weight: 400;
}
.fontW700 {
  font-weight: 700;
}

.mgL5px {
  margin-left: 5px;
}
.mgL10px {
  margin-left: 10px;
}

.mgT10px {
  margin-top: 10px;
}

.countDownTimerView span {
  display: inline-block;
  text-align: center;
  padding: 0 4px;
  font-size: 24px;
  font-weight: 700;
  font-family: sans-serif;
  margin: 0 2px;
  height: 32px;
  width: 26px;
  line-height: 32px;
  border-radius: 5px;
  color: #383b45;
  background-color: #f1f3ff;
  border-bottom: 4px solid #cdd4ff;
}

.res-app-logo {
  height: 45px !important;
}
.res-wallet-btns a {
  font-size: 18px !important;
  padding: 5px 35px !important;
  text-decoration: none;
}
.res-wallet-btns a:last-child {
  margin-left: 15px;
}

.res-timeout-view {
  width: 100%;
}
.res-timeout-view h1 {
  height: 180px;
  width: 130px;
  padding: 0 15px;
  font-size: 9em;
}
.res-ft-sz-18 {
  font-size: 18px;
}

.result-shortcut-icon {
  height: 25px;
  width: 25px;
}

.lotteryResultView {
  position: relative;
  width: 100%;
  height: 120px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #00b977;
}
.lotteryResultView::before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 0;

  background: #008b59;
  border-radius: 3px 0 0 3px;
  left: -5px;
}

.lotteryResultView::after {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 0;

  background: #008b59;
  border-radius: 0 3px 3px 0;
  right: -5px;
}

.lotteryResultView .lotteryCubesHolder {
  background: #003c26;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0.13333rem;
}

.lotteryCubesHolder::before {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  content: "";
  z-index: 3;

  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-right: 15px solid transparent;
  border-left: 15px solid #00b977;
}

.lotteryCubesHolder::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  content: "";
  z-index: 3;

  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-right: 15px solid #00b977;
  border-left: 15px solid transparent;
}

.diceView {
  width: calc((100% - 0.4rem) / 3);
  height: 85px;
  background-color: #333;
  border-radius: 0.13333rem;
  position: relative;
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
}
.diceView1 {
  background-image: url(../k3Lottery/icons/diceNum1.png);
}
.diceView2 {
  background-image: url(../k3Lottery/icons/diceNum2.png);
}
.diceView3 {
  background-image: url(../k3Lottery/icons/diceNum3.png);
}
.diceView4 {
  background-image: url(../k3Lottery/icons/diceNum4.png);
}
.diceView5 {
  background-image: url(../k3Lottery/icons/diceNum5.png);
}
.diceView6 {
  background-image: url(../k3Lottery/icons/diceNum6.png);
}

.joinBallView {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 15px;
}
.joinBallView div.ballView {
  width: 53px;
  height: 53px;
  margin: 0 auto;
  border-radius: 50%;
}

.joinBallView .greenBall {
  background: url(../k3Lottery/icons/greenBall_icon.png);
  background-size: cover;
}
.joinBallView .redBall {
  background: url(../k3Lottery/icons/redBal_icon.png);
  background-size: cover;
}

.joinBallView .greenBallTxt {
  background: -webkit-linear-gradient(top, #55d991 0%, #2f9c61 68.18%);
  background: linear-gradient(180deg, #55d991 0%, #2f9c61 68.18%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.joinBallView .redBallTxt {
  background: -webkit-linear-gradient(top, #ff827a 0%, #e93333 68.18%);
  background: linear-gradient(180deg, #ff827a 0%, #e93333 68.18%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.joinBigSmallOddEvenView {
  margin-top: 15px;
}
.joinBigSmallOddEvenView div {
  width: 100%;
  color: #ffffff;
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 5px;
}
.joinBigSmallOddEvenView div:not(:first-child) {
  margin-left: 10px;
}
.joinBigSmallOddEvenView div:nth-child(1) {
  background: #fc940e;
}
.joinBigSmallOddEvenView div:nth-child(2) {
  background: #0093ff;
}
.joinBigSmallOddEvenView div:nth-child(3) {
  background: #fa3c09;
}
.joinBigSmallOddEvenView div:nth-child(4) {
  background: #00c282;
}

.smallDiceView {
  width: 30px;
  height: 30px;
  border-radius: 0.13333rem;
  position: relative;
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
}
.smallDiceView1 {
  background-image: url(../k3Lottery/icons/dice1.png);
}
.smallDiceView2 {
  background-image: url(../k3Lottery/icons/dice2.png);
}
.smallDiceView3 {
  background-image: url(../k3Lottery/icons/dice3.png);
}
.smallDiceView4 {
  background-image: url(../k3Lottery/icons/dice4.png);
}
.smallDiceView5 {
  background-image: url(../k3Lottery/icons/dice5.png);
}
.smallDiceView6 {
  background-image: url(../k3Lottery/icons/dice6.png);
}

@media (max-width: 350px) {
  .res-app-logo {
    height: 35px !important;
  }

  .res-wallet-btns a {
    font-size: 18px !important;
    padding: 5px 25px !important;
  }

  .res-timeout-view h1 {
    height: 150px;
    width: 100px;
    padding: 0 15px;
    font-size: 8em;
  }
  .res-ft-sz-18 {
    font-size: 15px;
  }

  .t-countview span {
    height: 26px;
    width: 20px;
    line-height: 26px;
    font-size: 16px;
  }

  .result-shortcut-icon {
    height: 20px;
    width: 20px;
  }
}
