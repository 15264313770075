.res-app-logo{
    height: 45px !important;
}
.res-wallet-btns a{
    font-size: 18px !important;
    padding: 5px 35px !important;
    text-decoration: none;
}
.res-wallet-btns a:last-child{
    margin-left: 15px;
}

.res-timeout-view{
    width: 100%;
}
.res-timeout-view h1{
    height: 180px;
    width: 130px;
    padding: 0 15px;
    font-size: 9em;
}
.res-ft-sz-18{
    font-size: 18px;
}

.t-countview span{
    display: inline-block;
    text-align: center;
    padding: 0 4px;
    color: #383b45;
    font-size: 20px;
    font-weight: 700;
    font-family: sans-serif;
    margin: 0 2px;
    height: 32px;
    width: 26px;
    line-height: 32px;
    border-radius: 5px;
}

.result-shortcut-icon{
    height: 25px;
    width: 25px;
}

.joincolors-btns-view div{
    padding: 10px;
    border-radius: 5px;
}
.bigsmall-btns-view > div{
    width: 45%;
    padding: 10px;
}

@media (max-width: 350px) {
    .res-app-logo{
        height: 35px !important;
    }

    .res-wallet-btns a{
        font-size: 18px !important;
        padding: 5px 25px !important;
    }

    .res-timeout-view h1{
        height: 150px;
        width: 100px;
        padding: 0 15px;
        font-size: 8em;
    }
    .res-ft-sz-18{
        font-size: 15px;
    }

    .t-countview span{
        height: 26px;
        width: 20px;
        line-height: 26px;
        font-size: 16px;
    }

    .result-shortcut-icon{
        height: 20px;
        width: 20px;
    }

    .joincolors-btns-view div{
        padding: 5px 10px;
    }

    .bigsmall-btns-view > div{
        padding: 5px 10px;
    }
}