:root {
  --min-response-width: 430px;
}

.dialogView {
  position: fixed;
  top: 0;
  width: var(--min-response-width);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
}
.dialogContentView {
  text-align: center;
  max-width: 70%;
  min-width: 88px;
  width: 200px;
  min-height: 88px;
  border-radius: 6px;
  transition: 0.2s;
  padding: 16px;
  background: rgba(0, 0, 0, 0.8);
}
.dialogContentView img {
  height: 35px;
}
.dialogContentView div {
  color: #ffffff;
  font-weight: 200;
  word-break: break-word !important;
}
.dialogViewDeActive {
  display: none !important;
}

@media (max-width: 450px) {
  /* responsive width */
  .dialogView {
    width: 100% !important;
  }
}
