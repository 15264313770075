.navBarView {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 65px;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  z-index: 100;
  background: #000000;
  box-shadow: 0.1px 4px 8px 2px rgba(0, 0, 0, 0.1);
}

.navItemView {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  color: #769fde;
  background: #000000;
}
.navItemView svg {
  height: 1.6em;
  width: 1.6em;
}
.navItemView svg#LSize {
  font-size: 4em;
}
.navItemView p {
  font-size: 12px;
}
.navItemView:nth-child(3) p {
  margin-top: 30px;
}

.navBarView .navPromotionItemView {
  position: absolute;
  left: 50%;
  top: -30px;
  height: 65px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  transform: translateX(-50%);
}
.navBarView .navPromotionItemView div {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: -webkit-linear-gradient(top, #3c9cf6 0%, #4dd8ef 100%);
  background: linear-gradient(180deg, #3c9cf6 0%, #4dd8ef 100%);
}
.navBarView .navPromotionItemView img {
  height: 30px;
  z-index: 10;
}

.navItemAnimate {
  color: #43b5ec;
  animation-name: animateZoom2;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}

@keyframes animateZoom2 {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
