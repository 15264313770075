.topBarView{
    position: fixed;
    top: 0;
    z-index: 120;
    background: rgb(43, 50, 112);
}
.topBarView .headView{
    position: relative;
    padding: 5px 10px;
}
.topBarView .headView a{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);    
}
.topBarView .headView a img{
    height: 20px;  
}
.topBarView .headView > img{
    height: 40px;  
}