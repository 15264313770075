:root {
  --primary-color: #fa3c09;
  --color-blue: #0093ff;
  --color-orange: #ffa33b;
  --color-green: #00c282;
  --color-white: #ffffff;
  --color-drk-red: #f2413b;

  --min-response-width: 430px;
}

.positionRel {
  position: relative !important;
}

.w10px {
  width: 10px !important;
}
.w30px {
  width: 30px !important;
}
.w40px {
  width: 40px !important;
}
.w50px {
  width: 50px !important;
}

.fontS12Px {
  font-size: 12px;
}
.fontS13Px {
  font-size: 13px;
}
.fontS14Px {
  font-size: 14px;
}
.fontS15Px {
  font-size: 15px;
}
.fontS16Px {
  font-size: 16px;
}
.fontS18Px {
  font-size: 18px;
}
.fontS20Px {
  font-size: 20px;
}
.fontS25Px {
  font-size: 25px;
}
.fontS30Px {
  font-size: 30px;
}

.fontW400 {
  font-weight: 400;
}
.fontW700 {
  font-weight: 700;
}

.pd10px {
  padding: 10px;
}
.pd0px15px {
  padding: 0px 15px;
}
.pd10px15px {
  padding: 10px 15px;
}

.mg10px15px {
  margin: 10px 15px;
}
.mgL5px {
  margin-left: 5px;
}
.mgL10px {
  margin-left: 10px;
}

.mgT10px {
  margin-top: 10px;
}
.mgT25px {
  margin-top: 25px;
}

.txtAlignCenter{
  text-align: center;
}


.borderR10px {
  border-radius: 10px !important;
}

.bgOrange {
  background: var(--color-orange) !important;
}
.bgBlue {
  background: var(--color-blue) !important;
}
.bgWhite {
  background: var(--color-white) !important;
}
.bgGreen {
  background: var(--color-green) !important;
}

.bgActiveGreen {
  color: var(--color-white);
  background: var(--color-green) !important;
}

.posAbsLeftCenter{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.viewCenter {
  display: flex !important;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.flexDCol {
  flex-direction: column;
}

.dialogView{
  position: fixed;
  height: 100vh;
  width: var(--min-response-width);
  display: none;
  z-index: 1000;
  background: rgba(0,0,0,0.7);
}
.dialogView .dialogViewContents{
  position: relative;
  width: 85%;
}
.dialogView .dialogViewContents > img{
  position: absolute;
  width: 300px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.dialogView .dialogViewContentsHolder{
  position: absolute;
  width: 350px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.dialogView .dialogViewContentsHolder .dialogViewResults{
  top: -25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  
}
.dialogView .dialogViewContentsHolder .dialogViewResults span{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
.dialogView .dialogViewContentsHolder .dialogViewResults span:first-child{
  color: var(--color-white);
}
.dialogView .dialogViewContentsHolder .dialogTitle{
  top: -80px;
  color: var(--color-white);
  font-size: 25px;
}
.dialogView .dialogViewContentsHolder .dialogWinLossDetail{
  top: 35px;
  color: var(--color-drk-red);
  display: flex;
  flex-direction: column;
}
.dialogView .dialogViewContentsHolder .dialogWinLossAmount{
  line-height: 25px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}
.dialogView .dialogViewContentsHolder .dialogPeriodDetails{
  top: 92px;
}
.dialogView .dialogViewContentsHolder .dialogDismissBtn{
  height: 32px;
  width: 32px;
  top: 240px;
  border-radius: 50%;
  color: var(--color-white);
  background: rgba(255,255,255,0.3) !important;
}

.hideView{
  display: none !important;
}

@media (max-width: 450px) {
  /* responsive width */
  .dialogView{
     width: 100% !important;
  }
}