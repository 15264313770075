.noticeView{
    width: 100%;
    height: 52px;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    padding: 5px 10px;
    border-radius: 10px;
    max-height: 85px !important;
    background: #2B3270 !important;
}
.noticeView .noticeIconView{
    padding: 10px 0;
}
.noticeView .noticeIconView svg{
    width: 2rem;
    height: 2rem;
}
.noticeView .noticeBox{
    color: #FFFFFF;
    font-size: 14px;
    margin-left: 10px;
    animation: marquee-move-up-anim 7s linear infinite;
}

@keyframes marquee-move-up-anim {
    0% {
        -webkit-transform: translateY(150%);
        transform: translateY(150%);
    }
    10% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-150%);
        transform: translateY(-150%);
    }
} 