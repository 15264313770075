:root {
  --min-response-width: 430px;
}

.dialogView {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: var(--min-response-width);
  display: none;
  pointer-events: none;
  z-index: 1000;
  background: rgba(0,0,0,0.6);
}
.dialogViewActive{
    display: block !important;
}
.dialogView .dialogViewContents{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.dialogView .dialogViewContents img{
    position: absolute;
    height: 35px;
    
    animation-name: animateZoom;
    animation-duration: 0.5s;
    animation-direction: alternate-reverse;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.dialogView .dialogViewContents .dialogViewContentsLoading{
    height: 180px;
    width: 180px;
}

@keyframes animateZoom {
    0% {
        transform: scale(0.98);
    }
    100% {
        transform: scale(1.1);
    }
}

@media (max-width: 450px) {
  /* responsive width */
  .dialogView {
    width: 100% !important;
  }
}
