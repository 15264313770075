:root {
  --primary-color: #fa3c09;
  --color-white: #ffffff;

  --red-color: #fa3c09;
  --green-color: #00c282;
  --dark-green-color: #27ae60;
  --blue-color: #0093ff;
  --sky-blue-color: #aacdfa;
  --cyan-blue-color: #1aa4af;
  --violet-color: #6655d3;
  --yellow-color: #ffc107;
  --orange-color: #ffa33b;
  --black-color: #000000;
  --puff-color: #ffdec3;
  --color-violet: #eb43dd;

  --grey-color: #666;
  --light-grey-color: #959ea6;

  --simple-black: rgba(0, 0, 0, 0.5);
  --light-black: rgba(0, 0, 0, 0.05);
  --light-black-2: rgba(0, 0, 0, 0.9);

  --simple-white: rgba(255, 255, 255, 0.5);
  --light-white: rgba(255, 255, 255, 0.6);
  --min-response-width: 430px;

  --bg-app-gradient-1: linear-gradient(90deg, #f95959 0%, #ff9a8e 100%);
}

.colView {
  display: flex;
  flex-direction: column;
}

/* view align row wise */
.rowView {
  display: flex;
  align-items: center;
}

.viewCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hideView {
  display: none !important;
}

.spaceBetween {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}
.alignFlexStart {
  align-items: flex-start !important;
}

.pd10px {
  padding: 10px;
}
.pd15px {
  padding: 15px;
}

.mgT10px {
  margin-top: 10px;
}
.mgT15px {
  margin-top: 15px;
}
.mgT25px {
  margin-top: 25px;
}

.fontS12Px {
  font-size: 12px;
}
.fontS17Px {
  font-size: 17px;
}

.fontW400 {
  font-weight: 400;
}

.colorLightWhite {
  color: var(--light-white);
}

.splashScreenView .splashScreenImage {
  width: 270px;
}
.splashScreenView p {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 25px;
  letter-spacing: 1.1px;
}
.splashScreenView .splashScreenLogo {
  width: auto;
  height: 50px !important;
  margin-top: 60px;
}

.accountView {
  height: 230px;
  padding: 25px 15px 15px 15px;
  border-radius: 0 0 2.5rem 2.5rem;
  background: linear-gradient(90deg, #374992 0.14%, #4675d2 99.92%);
}
.accountOptionsView {
  padding: 15px;
  margin-top: -120px;
}

.accountOptionsView .accountBalanceView {
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 10px;
  background: #374992 !important;
}
.accountOptionsView .accountShortCutOptionsView {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 15px;
  grid-template-columns: repeat(2, 1fr);
}
.accountOptionsView .accountShortCutOptionsView a {
  color: var(--color-white);
  text-decoration: none;
  padding: 15px 10px;
  border-radius: 10px;
  background: #2b3270 !important;
}
.accountOptionsView .accountShortCutOptionsView a img {
  height: 35px;
}

.accountMoreOptionsView {
  margin-top: 15px;
  border-radius: 5px;
  background: #2b3270;
}
.accountMoreOptionsView a {
  padding: 15px 10px;
  text-decoration: none;
}
.accountMoreOptionsView a:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.accountMoreOptionsView a img {
  height: 28px;
  width: 28px;
}
.accountMoreOptionsView a span {
  color: var(--color-white);
  margin-left: 10px;
}

/* screen: login.js */
.loginTabSelectView {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  background: #374992;
  box-shadow: 0 -0.08rem 0.10667rem #4357a7 inset;
  align-items: center;
  min-height: 1.86667rem;
}
.loginTabSelectView span {
  font-size: 15px;
  margin-top: 5px;
  color: #759fde;
}

.loginTabSelectedView {
  background: -webkit-linear-gradient(319deg, #2979f2 15.85%, #2aaaf3 77.07%);
  background: linear-gradient(131deg, #2979f2 15.85%, #2aaaf3 77.07%);
  box-shadow: 0 -0.08rem 0.10667rem #63c5ff inset;
}
.loginTabSelectedView img {
  height: 25px;
  width: 25px;
}
.loginTabSelectedView span {
  color: #ffffff !important;
}

.loginView .inputView {
  padding: 15px;
}
.loginView .inputView img {
  height: 22px;
  width: 22px;
}
.loginView .inputView label {
  color: #ffffff;
  margin-left: 10px;
}

/* screen: home.js */
.homeView .topBar {
  position: sticky;
  top: 0;
  padding: 5px 10px;
  z-index: 10;
}
.homeView .homeGameOptionsView {
  padding: 15px;
}
.homeView .homeGameOptionsView .gameOptionsView1 {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}
.homeView .homeGameOptionsView .gameOptionsView2 {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 15px;
  grid-template-columns: repeat(3, 1fr);
}

.homeView .homeGameOptionsView .gameOptionsView1 div,
.homeView .homeGameOptionsView .gameOptionsView2 div {
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  border-radius: 5px;
  height: 85px;
}

.homeView .homeGameOptionsView .gameOptionsView1 div span,
.homeView .homeGameOptionsView .gameOptionsView2 div span {
  position: absolute;
  top: 2px;
  right: 5px;
  font-size: 13px;
  color: #ffffff;
}

.homeView .homeGameOptionsView .gameOptionsView1 div img,
.homeView .homeGameOptionsView .gameOptionsView2 div img {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 65px;
}

.homeView .homeGameOptionsView .bgCategory1 {
  background-image: url(../media/icons/backgrounds/1.png);
}
.homeView .homeGameOptionsView .bgCategory2 {
  background-image: url(../media/icons/backgrounds/2.png);
}
.homeView .homeGameOptionsView .bgCategory3 {
  background-image: url(../media/icons/backgrounds/3.png);
}
.homeView .homeGameOptionsView .bgCategory4 {
  background-image: url(../media/icons/backgrounds/4.png);
}
.homeView .homeGameOptionsView .bgCategory5 {
  background-image: url(../media/icons/backgrounds/5.png);
}
.homeView .homeGameOptionsView .bgCategory6 {
  background-image: url(../media/icons/backgrounds/6.png);
}

.homeView .homeGameViewBox {
  height: 120px;
  border-radius: 10px 10px 0 0;
  background-image: url(../media/icons/backgrounds/17.png),
    linear-gradient(153deg, #374992 5.33%, #4675d2 93.42%);
}

.homeView .winnersListStageView {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  width: 100%;
  height: 140px !important;
  margin-top: 4em;
  background: url(../media/icons/backgrounds/18.png) no-repeat center center /
    100% 100%;
}
.homeView .winnerPersonView {
  padding: 15px;
  border-radius: 10px;
  background: #2b3270;
}
.homeView .winnerPersonView span {
  color: #ffffff;
}

.homeView .winnerWonAmountView {
  min-width: 90px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  border-radius: 10px;
  padding: 5px 15px;
  background: rgba(117, 159, 222, 0.5);
}

.homeAdditionalGamesView {
  width: 100%;
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  padding: 15px;
  grid-template-columns: repeat(3, 1fr);
}
.homeAdditionalGamesView div {
  height: 160px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.homeAdditionalGamesView div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.homeAdditionalGamesTitle {
  color: #ffffff;
  padding: 15px;
  display: flex;
  align-items: center;
}
.homeAdditionalGamesTitle span {
  display: block;
  height: 30px;
  width: 5px;
  margin-right: 10px;
  background: #759fde !important;
}

/* screen: promotion */
.promotionDetailView {
  height: 250px;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

/* screen: activity */
.activityContentsView {
  padding: 20px;
  margin-top: 10px;
  padding-bottom: 150px;
}
.activityListView {
  width: 100%;
  border-radius: 10px;
}
.activityListView img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.activityListView span {
  display: inline-block;
  color: #ffffff;
  padding: 10px;
}

/* screen: wallet */
.walletDetailView {
  height: 250px;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.withdrawlView {
}
.withdrawlViewCard {
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
}

.withdrawViewBankCard a span {
  display: block;
}


.withdrawlOperateView {
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
}
.withdrawlInputView {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border-radius: 30px;
  background: #374992;
}
.withdrawlInputView span {
  position: relative;
  color: #61a9ff;
  font-size: 25px;
  margin-right: 30px;
}
.withdrawlInputView span::after {
  position: absolute;
  content: "";
  right: -20px;
  top: 50%;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  border-right: 1.5px solid #bdbdbd;
  transform: translateY(-50%);
}
.withdrawlInputView input {
  color: #ffffff;
}

.withdrawlRulesView {
  color: #fff;
  padding: 10px;
  margin-top: 15px;
  border-radius: 10px;
  border: 1px solid #374992;
  background: #2b3270;
}
.withdrawlRulesView p {
  position: relative;
  font-size: 14px;
  padding-left: 15px;
}
.withdrawlRulesView p:not(:first-child) {
  margin-top: 10px;
}
.withdrawlRulesView p span {
  display: inline-block;
  margin: 0 3px;
  color: #61a9ff;
}
.withdrawlRulesView p::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 6px;
  height: 6px;
  background: #759fde;
}

/* screen: firstdepositbonus */
/* .firstDepositBonusItem{

} */